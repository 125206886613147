import { LoadingState } from '@core/models/general';
import { WorkspaceMetadata } from '@features/workspace/models/workspace.models';

export const WORKSPACES_STATE_NAME = 'workspaces';
export type WorkspacesState = {
  workspacesMetadata: WorkspaceMetadata[] | null;
  workspacesMetadataLoadingState: LoadingState;
  mode: 'local' | 'cloud' | 'embedded' | null;
};

export const workspacesState: WorkspacesState = {
  workspacesMetadata: null,
  workspacesMetadataLoadingState: 'pending',
  mode: null,
};
