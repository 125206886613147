import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { userInterfaceSelectors } from 'src/app/store/user-interface/user-interface.selectors';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { tap } from 'rxjs';

@Component({
  selector: 'app-global-loader',
  standalone: true,
  imports: [CommonModule, LoaderComponent],
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalLoaderComponent {
  @HostBinding('class.hidden')
  isHidden = true;

  constructor(private store: Store, private chDetRef: ChangeDetectorRef) {
    this.store
      .select(userInterfaceSelectors.globalLoader)
      .pipe(
        tap((e) => (this.isHidden = !e)),
        tap(() => this.chDetRef.markForCheck())
      )
      .subscribe();
  }
}
