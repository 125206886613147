import { NgModule, isDevMode } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from '@core/components/error-page/error-page.component';
import { NotFoundPageComponent } from '@core/components/not-found-page/not-found-page.component';

const routes: Routes = [
  {
    path: '',
    title: 'Market Pulse Web',
    pathMatch: 'full',
    redirectTo: '/workspace',
  },
  {
    path: 'workspace',
    title: 'Workspace',
    loadChildren: () =>
      import('./features/workspace-page/workspaces-page.module').then(
        (m) => m.WidgetsWorkspacesPage
      ),
  },
  {
    path: 'external',
    loadChildren: () =>
      import('./features/external/external.module').then(
        (m) => m.ExternalModule
      ),
  },
  {
    path: 'not-found',
    component: NotFoundPageComponent,
  },
  {
    path: 'error',
    component: ErrorPageComponent,
  },
  {
    path: '**',
    title: 'Not Found',
    redirectTo: '/not-found',
  },
];

const devRoutes: Routes = [
  {
    path: 'dev',
    title: 'Dev Page',
    loadChildren: async () => {
      const m = await import('./features/dev-test/dev-test.module');
      return m.DevTestModule;
    },
  },
  { path: 'dev-test', redirectTo: '/dev' },
];

const finalRoutes = isDevMode() ? [...devRoutes, ...routes] : routes;

@NgModule({
  imports: [RouterModule.forRoot(finalRoutes)],
  providers: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
