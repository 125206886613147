export type LoadingState = 'pending' | 'loading' | 'loaded' | 'error';

export type Tuple7<T> = [T, T, T, T, T, T, T];

/** Designed to simplify custom date formatting based on ms (`Date.now()`) */
export type SplittedDate = {
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
};

export const weekDays = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];
export type Weekday = (typeof weekDays)[number];

export type ScheduleItem = {
  openingTime: {
    hour: number;
    minute: number;
  };
  closingTime: {
    hour: number;
    minute: number;
  };
};

/** `null` - not working day  */
export type TupleWeekSchedule = Tuple7<ScheduleItem[] | null>;

/** Exchange schedule received from the server  */
export type ExchangeInfoResponse = {
  shortName: string;
  fullName: string;
  weekSchedule: {
    schedule: Record<Weekday, ScheduleItem[]>;
  };
};

export type ScheduleState = {
  stateStartDate: Date;
  nextEventExpectedDate: Date;
  currentState: 'OPEN' | 'CLOSE';
};

export type ExchangeInfoSchedule = TupleWeekSchedule | '24/7';

export type ExchangeInfo = {
  shortName: string;
  fullName: string;
  weekSchedule: {
    schedule: ExchangeInfoSchedule;
  };
};

export type Direction = 'ascending' | 'descending';
