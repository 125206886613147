<div class="active-zone" (click)="clickAction.emit()">
  <h2>
    <ng-content select="[header]"></ng-content>
  </h2>
  <div class="text-large-regular color-secondary-l3">
    <ng-content select="[body]"></ng-content>
  </div>
  @if (actionText) {
    <div class="action">
      {{ actionText }}
    </div>
  }
</div>
<button
  aria-label="Close notification"
  class="close-button"
  (click)="clickClose.emit()"
>
  <app-color-svg src="/assets/icons/cross.svg"></app-color-svg>
</button>
