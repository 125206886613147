import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastNotificationComponent } from './toast-notification.component';
import { ColorSvgComponent } from '@shared/components/color-svg/color-svg.component';

@NgModule({
  imports: [CommonModule, ColorSvgComponent, ToastNotificationComponent],
  exports: [ToastNotificationComponent],
})
export class ToastNotificationModule {}
