import { DialogConfig } from '@angular/cdk/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ModalFrameComponent } from '@shared/components/modal-frame/modal-frame.component';

export const CUSTOM_DIALOG_CONFIG: DialogConfig = {
  container: ModalFrameComponent,
  hasBackdrop: true,
  disableClose: false,
  scrollStrategy: new NoopScrollStrategy(),
  backdropClass: 'dialog-backdrop-custom-style',
};
