@if ((notificationsNumber$ | async) ?? 0 > 0) {
  <div class="notification-background" [@fadeAnimation]></div>
}

<div class="notification-list" [class.hidden]="!notificationsShown">
  @if (toastService.notifications$ | async; as notifications) {
    @if (notifications.length > 0) {
      <section class="compact" [@moveAnimation]>
        @for (notification of notifications; track notification.id) {
          <app-notification-card-compact
            class="notification-card"
            [type]="notification.type"
            [actionText]="notification.actionText ?? 'OK'"
            @moveAnimation
            (clickAction)="handleAction(notification)"
            (clickClose)="handleClose(notification)"
          >
            {{ notification.body }}
          </app-notification-card-compact>
        }
      </section>
    }
  }

  @if (toastService.promoNotifications$ | async; as proms) {
    @if (proms.length > 0) {
      <section class="promo" [@moveAnimation]>
        @for (notification of proms; track notification.id) {
          <app-notification-card-promo
            class="notification-card"
            [@moveAnimation]
            [actionText]="notification.actionText ?? 'Learn more'"
            (clickAction)="handleAction(notification)"
            (clickClose)="handleClose(notification)"
          >
            <ng-container header>{{ notification.header }}</ng-container>
            <ng-container body>{{ notification.body }}</ng-container>
          </app-notification-card-promo>
        }
      </section>
    }
  }

  @if (toastService.soundNotification$ | async) {
    <section class="sound" [@moveAnimation]>
      <app-notification-card-no-sound
        class="notification-card"
        [@moveAnimation]
      ></app-notification-card-no-sound>
    </section>
  }
</div>
