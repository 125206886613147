import { Injectable } from '@angular/core';
import { WorkspacesApiService } from '@core/services/api/workspaces-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { workspacesActions } from './workspaces.actions';
import { Store } from '@ngrx/store';
import {
  catchError,
  combineLatest,
  exhaustMap,
  of,
  switchMap,
  map,
} from 'rxjs';
import { WorkspaceMetadata } from '@features/workspace/models/workspace.models';
@Injectable()
export class WorkspaceEffects {
  constructor(
    private actions$: Actions,
    private workspacesApi: WorkspacesApiService,
    private store: Store
  ) {}

  /**
   * LOAD METADATA FOR ALL WORKSPACES
   */
  loadMetadataAllStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(workspacesActions.loadMetadataAllStart),
      exhaustMap(() => {
        return this.workspacesApi.getWorkspacesMetadata().pipe(
          switchMap((metadata) => {
            this.store.dispatch(workspacesActions.setMode({ mode: 'cloud' }));
            if (metadata.length > 0) {
              return of(workspacesActions.loadMetadataAllSuccess({ metadata }));
            }
            return this.workspacesApi.getWorkspacesTemplatesDefaults().pipe(
              switchMap((templates) => {
                const emptyWorkspace = this.workspacesApi
                  .createWorkspace('New Workspace')
                  .pipe(map((v) => [v]));

                const workspaceFromTemplate = (template: WorkspaceMetadata) =>
                  this.workspacesApi
                    .getWorkspaceTemplateById(template.id)
                    .pipe(
                      switchMap((widgets) =>
                        this.workspacesApi.createWorkspace(
                          template.name,
                          widgets
                        )
                      )
                    );

                const templatedWorkspaces = combineLatest([
                  ...templates.map(workspaceFromTemplate),
                ]);

                return (
                  templates.length > 0 ? templatedWorkspaces : emptyWorkspace
                ).pipe(
                  map(() => {
                    setTimeout(() => {
                      this.store.dispatch(
                        workspacesActions.loadMetadataAllStart()
                      );
                    });
                    return {
                      type: 'EMPTY',
                    };
                  })
                );
              })
            );
          }),
          catchError(() => {
            this.store.dispatch(workspacesActions.setMode({ mode: 'local' }));
            return of(workspacesActions.loadMetadataAllFailure({}));
          })
        );
      })
    );
  });
}
