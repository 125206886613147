import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ToastNotificationModule } from '@features/toast-notification/toast-notification.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appReducers } from './store/app.reducer';
import { TokenInterceptor } from '@core/helpers/token.interceptor';
import { UrlInterceptor } from '@core/helpers/url.interceptor';
import { GlobalLoaderComponent } from '@core/components/global-loader/global-loader.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { APP_CONFIG, appConfig } from '@core/configs/app.config';
import { appEffects } from './store/app.effects';
import {
  APP_WIDGET_CONFIG,
  widgetAppConfig,
} from '@core/configs/widget-app.config';
import {
  WIDGET_SETTINGS_APP_CONFIG,
  widgetSettingsAppConfig,
} from '@core/configs/widget-settings-app.config';
import { DEFAULT_DIALOG_CONFIG } from '@angular/cdk/dialog';
import { CUSTOM_DIALOG_CONFIG } from '@core/configs/ckd-dialog.config';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastNotificationModule,
    StoreModule.forRoot(appReducers, {}),
    EffectsModule.forRoot(appEffects),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !isDevMode(),
      connectInZone: true,
    }),
    GlobalLoaderComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlInterceptor,
      multi: true,
    },
    {
      provide: APP_CONFIG,
      useValue: appConfig,
    },
    {
      provide: APP_WIDGET_CONFIG,
      useValue: widgetAppConfig,
    },
    {
      provide: WIDGET_SETTINGS_APP_CONFIG,
      useValue: widgetSettingsAppConfig,
    },
    {
      provide: DEFAULT_DIALOG_CONFIG,
      useValue: CUSTOM_DIALOG_CONFIG,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
