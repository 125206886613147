export const barColors = {
  green: '#1fdb51',
  red: '#eb1734',
  pink: '#EF18E7',
  purple: '#9142F6',
  magenta: '#0DD1BD',
  yellow: '#FFF374',
  blue: '#458FFF',
  orange: '#FF9114',
};
