import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { ToastNotificationIconName } from '../toast-notifications.model';
import { ColorSvgComponent } from '@shared/components/color-svg/color-svg.component';

@Component({
  selector: 'app-notification-card-compact',
  standalone: true,
  imports: [ColorSvgComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './notification-card-compact.component.html',
  styleUrl: './notification-card-compact.component.scss',
  host: {
    'aria-role': 'alert',
    'aria-live': 'polite',
  },
})
export class NotificationCardCompactComponent {
  @Input() public type: ToastNotificationIconName = 'info';
  @Input() public actionText?: string;
  @Output() public clickAction = new EventEmitter<void>();
  @Output() public clickClose = new EventEmitter<void>();

  @HostBinding('class') protected get cssClass() {
    return this.type;
  }
}
