import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ColorSvgComponent } from '@shared/components/color-svg/color-svg.component';

@Component({
  selector: 'app-notification-card-no-sound',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ColorSvgComponent],
  templateUrl: './notification-card-no-sound.component.html',
  styleUrl: './notification-card-no-sound.component.scss',
})
export class NotificationCardNoSoundComponent {}
