import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  USER_INTERFACE_STATE_NAME,
  UserInterfaceState,
} from './user-interface.state';

const getUiState = createFeatureSelector<UserInterfaceState>(
  USER_INTERFACE_STATE_NAME
);
export const userInterfaceSelectors = {
  globalLoader: createSelector(getUiState, (state) => state.globalLoader),
};
