import { Observable, of, tap } from 'rxjs';

type MemoizedObservableConfig = {
  cacheLifetime?: number | 'forever';
};

/** Designed to work with http responses. Does not support continuos streams.
 *
 * Usage:
 * ```typescript
 * const obs = new MemoizedObservable(http.get('some-url'), { cacheLifetime: 1000 });
 *
 * obs.getValue(); // returns null
 * obs.getStream().subscribe(console.log); // makes http request
 * obs.getStream().subscribe(console.log); // does not make http request
 * obs.getValue(); // returns response from http request
 * setTimeout(() => { obs.getStream().subscribe(console.log); }, 2000); // makes http request
 * ```
 */
export class MemoizedObservable<T> {
  private _cache: T | null = null;
  private _lastUpdateTimestamp: number = 0;
  private _cacheTime: number | 'forever';

  constructor(
    private obs: Observable<T>,
    { cacheLifetime = 'forever' }: MemoizedObservableConfig = {}
  ) {
    this._cacheTime = cacheLifetime;
  }

  public getStream(): Observable<T> {
    if (!this._cache) return this._getFreshData();
    if (this._cacheTime === 'forever') return of(this._cache);
    if (this._lastUpdateTimestamp + this._cacheTime < Date.now()) {
      return this._getFreshData();
    }
    return of(this._cache);
  }

  public getValue(): T | null {
    return this._cache;
  }

  public resetCache() {
    this._lastUpdateTimestamp = 0;
  }

  private _getFreshData(): Observable<T> {
    return this.obs.pipe(
      tap((response) => {
        this._cache = response;
        this._lastUpdateTimestamp = new Date().getTime();
      })
    );
  }
}
