import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { combineLatest, map } from 'rxjs';
import { ToastNotificationService } from './toast-notification.service';
import { toastNotificationsAnimations } from './toast-notification.animations';
import { AsyncPipe } from '@angular/common';
import { ColorSvgComponent } from '@shared/components/color-svg/color-svg.component';
import { NotificationCardCompactComponent } from './notification-card-compact/notification-card-compact.component';
import { NotificationCardNoSoundComponent } from './notification-card-no-sound/notification-card-no-sound.component';
import { NotificationCardPromoComponent } from './notification-card-promo/notification-card-promo.component';
import { BasicNotificationInfo } from './toast-notifications.model';

@Component({
  selector: 'app-toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
  animations: toastNotificationsAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    ColorSvgComponent,
    NotificationCardCompactComponent,
    NotificationCardNoSoundComponent,
    NotificationCardPromoComponent,
  ],
  host: {
    '[attr.aria-hidden]': '_notificationNumber === 0',
    role: 'region',
    'aria-label': 'Notifications',
    'aria-live': 'polite',
  },
})
export class ToastNotificationComponent {
  public notificationsNumber$ = combineLatest([
    this.toastService.promoNotifications$,
    this.toastService.notifications$,
  ]).pipe(map(([prom, compact]) => prom.length + compact.length));
  public notificationsShown = true;

  constructor(
    protected toastService: ToastNotificationService,
    private _chDetRef: ChangeDetectorRef
  ) {
    this.toastService.shouldHideNotifications$.subscribe((shouldHide) => {
      this.notificationsShown = !shouldHide;
      this._chDetRef.markForCheck();
    });
  }

  protected handleAction(notification: BasicNotificationInfo) {
    notification.actionHandler?.();
    this.toastService.close(notification.id);
  }

  protected handleClose(notification: BasicNotificationInfo) {
    notification.closeHandler?.();
    this.toastService.close(notification.id);
  }
}
