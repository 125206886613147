import { createReducer, on } from '@ngrx/store';
import { workspacesState } from './workspaces.state';
import { workspacesActions } from './workspaces.actions';
import { LoadingState } from '@core/models/general';

export const workspacesReducer = createReducer(
  workspacesState,

  on(workspacesActions.loadMetadataAllStart, (state) => ({
    ...state,
    workspacesMetadataLoadingState: 'loading' as LoadingState,
  })),
  on(workspacesActions.loadMetadataAllSuccess, (state, { metadata }) => ({
    ...state,
    workspacesMetadata: metadata,
    workspacesMetadataLoadingState: 'loaded' as LoadingState,
  })),
  on(workspacesActions.loadMetadataAllFailure, (state) => ({
    ...state,
    workspacesMetadataLoadingState: 'error' as LoadingState,
  })),

  on(workspacesActions.setMode, (state, { mode }) => ({
    ...state,
    mode: mode,
  }))
);
