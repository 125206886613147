import { createReducer, on } from '@ngrx/store';
import { initialAuthState } from './auth.state';
import { authActions } from './auth.actions';
import { LoadingState } from '@core/models/general';

export const authReducer = createReducer(
  initialAuthState,
  on(authActions.getUserProfileStart, (state) => ({
    ...state,
    userLoadingState: 'loading' as LoadingState,
  })),
  on(authActions.getUserProfileSuccess, (state, action) => ({
    ...state,
    userLoadingState: 'loaded' as LoadingState,
    user: action.userData,
  })),
  on(authActions.getUserProfileFailure, (state) => ({
    ...state,
    userLoadingState: 'error' as LoadingState,
    user: null,
  })),

  on(authActions.loginStart, (state) => ({
    ...state,
    loginLoadingState: 'loading' as LoadingState,
  })),
  on(authActions.loginSuccess, (state) => ({
    ...state,
    loginLoadingState: 'loaded' as LoadingState,
  })),
  on(authActions.loginFailure, (state) => ({
    ...state,
    loginLoadingState: 'error' as LoadingState,
  })),
  on(authActions.loginReset, (state) => ({
    ...state,
    loginLoadingState: 'pending' as LoadingState,
  }))
);
