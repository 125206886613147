import { Injectable } from '@angular/core';
import { ExchangeActiveSchedule } from '@core/classes/exchange-active-schedule.class';
import {
  ExchangeInfo,
  ExchangeInfoResponse,
  ScheduleItem,
  TupleWeekSchedule,
  Weekday,
  weekDays,
} from '@core/models/general';
import { TickService } from './tick.service';
import { tap } from 'rxjs';

/**
 * Service for managing exchange info. Can work directly with info received from
 * the server. Service transforms exchange data to internal format & provides
 * useful {@link ExchangeActiveSchedule | Active Schedule } objects with stream
 * of schedule state changes (open/close).
 */
@Injectable({
  providedIn: 'root',
})
export class ExchangeInfoService {
  /** Info about exchanges & its schedules */
  private _exchangesInfo: Record<string, ExchangeInfo> = {};
  /** Collection of exchange active schedules */
  private _exchangesSchedules: Record<string, ExchangeActiveSchedule> = {};

  constructor(private _tick: TickService) {
    this._tick.generator1000$
      .pipe(
        tap(() => {
          Object.values(this._exchangesSchedules).forEach((s) => s.tick());
        })
      )
      .subscribe();
  }

  /** Completely rewrites internal exchange info */
  public updateExchangeInfo(info: ExchangeInfoResponse[]) {
    this._exchangesInfo = this._patchExchangeInfo(info);
    this._exchangesSchedules = Object.fromEntries(
      Object.entries(this._exchangesInfo).map(
        ([exchangeShortName, exchangeInfo]) => {
          return [exchangeShortName, new ExchangeActiveSchedule(exchangeInfo)];
        }
      )
    );
  }

  /** Returns exchange info by exchange short name */
  public getExchangeInfo(exchangeShortName: string): ExchangeInfo | null {
    return this._exchangesInfo[exchangeShortName] ?? null;
  }

  /** Returns exchange active schedule object by exchange short name */
  public getExchangeSchedule(
    exchangeShortName: string
  ): ExchangeActiveSchedule | null {
    return this._exchangesSchedules[exchangeShortName] ?? null;
  }

  /** Transform schedule from format from the server to internal one */
  private _patchExchangeInfo(
    info: ExchangeInfoResponse[]
  ): Record<string, ExchangeInfo> {
    return Object.fromEntries(
      info.map((info) => {
        return [info.shortName, this._patchExchangeInfoElement(info)];
      })
    );
  }

  /**
   * Fills all absent days with `null`. Replaces all *00:00-23:59* records with
   * `FULL_DAY`. Changes structure from `[day]: schedule` to `schedule[]`
   */
  private _patchExchangeInfoElement(info: ExchangeInfoResponse): ExchangeInfo {
    /** Blank schedule. Some of (or every) `null` may be replaced in next step */
    const weekScheduleArr: TupleWeekSchedule = Array(7).fill(
      null
    ) as TupleWeekSchedule;

    /* FOR TESTS */
    /* FOR TESTS */
    let testSchedule: Record<Weekday, ScheduleItem[]> | undefined;
    const lsRecord = localStorage.getItem('TEST_SCHEDULE');
    if (lsRecord && typeof lsRecord === 'string') {
      testSchedule = JSON.parse(lsRecord) as Record<Weekday, ScheduleItem[]>;
    }
    /* FOR TESTS */
    /* FOR TESTS */

    Object.entries(testSchedule ?? info.weekSchedule.schedule).forEach(
      ([dayName, scheduleItemArr]) => {
        /** Index of day in week. Sunday - 0, Monday - 1... */
        const dayNameIndex = weekDays.indexOf(dayName);

        if (dayNameIndex < 0) {
          console.warn(`Can't find index of day`, dayName);
          return;
        }

        weekScheduleArr[dayNameIndex] = scheduleItemArr;
      }
    );

    return {
      ...info,
      weekSchedule: {
        schedule: weekScheduleArr.every(
          (schItm) => schItm !== null && this._isFullDay(schItm)
        )
          ? '24/7'
          : weekScheduleArr,
      },
    };
  }

  /** If equal 00:00-23:59 - true */
  private _isFullDay(scheduleItemArr: ScheduleItem[]): boolean {
    if (
      scheduleItemArr.length === 1 &&
      scheduleItemArr[0].openingTime.hour === 0 &&
      scheduleItemArr[0].openingTime.minute === 0 &&
      scheduleItemArr[0].closingTime.hour === 23 &&
      scheduleItemArr[0].closingTime.minute === 59
    ) {
      return true;
    }
    return false;
  }
}
