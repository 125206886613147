import { InjectionToken } from '@angular/core';
import { AppConfig } from '@core/models/config';
import { minutesToMs } from '@core/utils';
import { widgetAppConfig } from './widget-app.config';

export const appConfig: AppConfig = {
  widget: widgetAppConfig,
  auth: {
    refreshTime: minutesToMs(5),
  },
  external: {
    handshakingInterval: 1000,
  },
  ui: {
    feedbackDialogTimeout: minutesToMs(5),
  },
  analytics: {
    widgetSoundDurationTrigger: 30_000,
    widgetDoublesUsageTimeTrigger: 600_000,
  },
  cacheLifetime: minutesToMs(5),
  workspace: {
    widgetsNumberLimit: 6,
  },
};
export const APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG');
