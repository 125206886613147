import { UserData } from '@core/models/auth';
import { LoadingState } from '@core/models/general';

export const AUTH_STATE_NAME = 'auth';
export type AuthState = {
  user: UserData | null;
  userLoadingState: LoadingState;
  loginLoadingState: LoadingState;
};

export const initialAuthState: AuthState = {
  user: null,
  userLoadingState: 'pending',
  loginLoadingState: 'pending',
};
