import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const userInterfaceActions = createActionGroup({
  source: 'UI',
  events: {
    'Loader Open': emptyProps(),
    'Loader Close': emptyProps(),

    'Show Toast Info': props<{ text: string }>(),
    'Show Toast Error': props<{ text: string }>(),
    'Show Toast Success': props<{ text: string }>(),
    'Show Toast Warning': props<{ text: string }>(),
  },
});
