import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-page',
  standalone: true,
  imports: [],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPageComponent {
  constructor(private _router: Router) {
    document.querySelector('.app-skeleton')?.remove();
  }

  protected reloadPage() {
    this._router.navigate(['/']).then(() => {
      location.reload();
    });
  }
}
