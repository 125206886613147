import { InjectionToken } from '@angular/core';
import { barColors } from '@core/constants/widget-visualization';
import { soundsUrl } from '@core/constants/sounds-url';
import { WidgetSettingsAppConfig } from '@core/models/config';

export const widgetSettingsAppConfig: WidgetSettingsAppConfig = {
  defaults: {
    volume: 0.5,
    threshold: 0.75,
    positiveSoundUrl: soundsUrl.positive[1],
    negativeSoundUrl: soundsUrl.negative[1],
    selectedPresetId: 'Medium',
    selectedViewId: 'Trades',
    misc: {},
  },
  defaultsExtra: {
    relativeValue: false,
    color1: barColors.red,
    color2: barColors.green,
  },
};
export const WIDGET_SETTINGS_APP_CONFIG =
  new InjectionToken<WidgetSettingsAppConfig>('WIDGET_SETTINGS_APP_CONFIG');
