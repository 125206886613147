<div
  class="background"
  [class.background-hidden]="isHidden"
  aria-hidden="true"
></div>
<app-loader
  class="loader"
  [class.loader-hidden]="isHidden"
  [attr.aria-hidden]="isHidden"
></app-loader>
