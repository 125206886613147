import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SHOULD_RESOLVE_URL } from '@core/helpers/url.interceptor';
import { stringToHash } from '@core/utils';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { v4 as uuid } from 'uuid';

declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext;
  }
}

type PlaybackSession = {
  soundsIds: string[];
  gain: GainNode;
};

@Injectable({
  providedIn: 'root',
})
export class SoundPlayerService {
  private EMPTY_SOUND = new Audio('/assets/sounds/empty.mp3');
  private _context = new (window.AudioContext || window.webkitAudioContext)();
  private _sessions: Record<string, PlaybackSession> = {};
  private _sounds: Record<string, AudioBuffer | null> = {};
  private _locked$ = new BehaviorSubject<boolean>(true);
  public get locked$(): Observable<boolean> {
    return this._locked$;
  }
  public get locked() {
    return this._locked$.value;
  }

  private _globalNodes = {
    compressor: this._context.createDynamicsCompressor(),
  };

  constructor(private http: HttpClient) {
    /* TODO: keep or delete */
    const mutedGain = this._context.createGain();
    mutedGain.gain.value = 0.001;
    this._sessions = {
      unlock: {
        soundsIds: [],
        gain: mutedGain,
      },
    };
    this.setSounds('unlock', ['/assets/sounds/1P.wav']);
    /* --- --- --- --- --- */
    this._initAutoUnlock();
  }

  private _configureGlobalNodes() {
    this._globalNodes.compressor.threshold.setValueAtTime(-50, 0);
    this._globalNodes.compressor.knee.setValueAtTime(40, 0);
    this._globalNodes.compressor.ratio.setValueAtTime(20, 0);
  }

  public createSession(): string {
    const sessionId = uuid();
    this._sessions[sessionId] = {
      soundsIds: [],
      gain: this._context.createGain(),
    };

    return sessionId;
  }

  public deleteSession(sessionId: string) {
    if (this._sessions[sessionId]) delete this._sessions[sessionId];
  }

  public setSounds(sessionId: string, urlArr: string[]) {
    if (!this.isSessionExist(sessionId)) return;
    this.resetSounds(sessionId);
    urlArr.forEach((url) => {
      const hash = stringToHash(url);
      this._sessions[sessionId].soundsIds.push(hash);
      if (!this._sounds[hash]) {
        this._sounds[hash] = null;
        this.http
          .get(url, {
            responseType: 'arraybuffer',
            /* to avoid API URL resolving `cause it's not API */
            context: new HttpContext().set(SHOULD_RESOLVE_URL, false),
          })
          .pipe(switchMap((buffer) => this.bufferToAudioBuffer(buffer)))
          .subscribe((buffer) => {
            this._sounds[hash] = buffer;
          });
      }
    });
  }

  /** Set sound volume of session */
  public setVolume(sessionId: string, value: number) {
    if (Math.abs(value) > 1) throw new Error('Volume should be in range 0-1');
    if (!this.isSessionExist(sessionId)) return;
    this._sessions[sessionId].gain.gain.value = value;
  }

  /** Delete all saved sound for session */
  public resetSounds(sessionId: string) {
    if (!this.isSessionExist(sessionId)) return;
    this._sessions[sessionId].soundsIds = [];
  }

  public playSound(sessionId: string, soundIndex = 0) {
    if (this.locked) return;
    if (!this.isSessionExist(sessionId)) return;
    const session = this._sessions[sessionId];
    const soundId = session.soundsIds[soundIndex];
    const audioBuffer = this._sounds[soundId];
    if (!audioBuffer) return;
    this.playAudioBuffer(audioBuffer, session.gain);
  }

  /**
   * Cheat method to unlock audio context (it should be unlocked by user's
   * interaction with the page)
   */
  public unlock() {
    if (!this.locked) return;
    this._locked$.next(false);
    this.playSound('unlock');
    this.EMPTY_SOUND.play();
    this._context.resume();
  }

  private _initAutoUnlock() {
    const unlock = () => {
      this.unlock();
    };
    ['click', 'touchstart', 'touchend'].forEach((evName) =>
      document.addEventListener(evName, unlock)
    );
  }

  private playAudioBuffer(buffer: AudioBuffer, gain: GainNode): void {
    const source = this._context.createBufferSource();

    source.buffer = buffer;
    source
      .connect(gain)
      .connect(this._globalNodes.compressor)
      .connect(this._context.destination);
    source.start();
  }

  private isSessionExist(sessionId: string): boolean {
    if (this._sessions[sessionId]) return true;
    console.error('Player: Session not found!');
    return false;
  }

  private bufferToAudioBuffer(buffer: ArrayBuffer): Observable<AudioBuffer> {
    return new Observable((subscriber) => {
      this._context.decodeAudioData(buffer, (audioBuffer) => {
        subscriber.next(audioBuffer);
        subscriber.complete();
      });
    });
    /**
     * This line doesn't work on mobile Safari:
     *
     * ``` return from(this.context.decodeAudioData(buffer)); ```
     *
     * Usually it returns Promise<AudioBuffer>, but on mobile safari it returns
     * just undefined.
     **/
  }
}
