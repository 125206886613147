import { ComponentType } from '@angular/cdk/portal';
import { Routes } from '@angular/router';
import { Key, KeyUniqProps, RawKey } from './models/meta';

/**
 * Function clamps a middle value within a range of values between a defined
 * minimum bound and a maximum bound. The function takes three parameters: a
 * minimum value, a preferred value, and a maximum allowed value
 */
export function clamp(min: number, value: number, max: number) {
  return Math.min(Math.max(min, value), max);
}

export function isInRange(value: number, min: number, max: number): boolean {
  return value >= min && value <= max;
}

/**
 * Scales value from @param rangeFrom to @param rangeTo .
 *
 * @param value - value to scale
 * @param rangeFrom - value is withing this range
 * @param rangeTo - desired range to scale
 */
export function normalize(
  value: number,
  rangeFrom: [number, number],
  rangeTo: [number, number] = [0, 1]
): number {
  return (
    ((value - rangeFrom[0]) / (rangeFrom[1] - rangeFrom[0])) *
      (rangeTo[1] - rangeTo[0]) +
    rangeTo[0]
  );
}

/**
 * Generates blank route. Suitable for lazy-loading modules, that require route
 * with blank path for single component.
 *
 * Instead of writing this:
 *
 * ```ts
 * RouterModule.forChild([{
 *      path: '',
 *      component: YourComponent
 *    }])
 * ```
 * you can use this function:
 *
 * ```ts
 * RouterModule.forChild(blankRoute(YourComponent))
 * ```
 *
 */
export function blankRoute<T>(component: ComponentType<T>): Routes {
  return [
    {
      path: '',
      component: component,
    },
  ];
}

export function stringToHash(text: string): string {
  let hash = 0;

  if (text.length == 0) return hash.toString();

  for (let i = 0; i < text.length; i++) {
    const char = text.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return hash.toString();
}

/**
 * Parses cookies string to object [cookie name: cookie value]
 */
export function parseCookie(cookie: string): Record<string, string> {
  const cookieLines = cookie.split(';');
  const cookieEntries = cookieLines.map((line) =>
    line.split('=').map((entry) => entry.trim())
  );
  return Object.fromEntries(cookieEntries);
}

let frameId: number | null = null;
export function getFrameId(): number {
  const calcFrameId = () => {
    let result = -1;
    const frames = window.parent.frames;
    for (let i = 0; i < frames.length; i++) {
      if (frames[i] === window) result = i;
    }
    frameId = result;
    return result;
  };

  if (frameId) return frameId;
  return calcFrameId();
}

/**
 * Checks is input an object and does it have all of `keys` keys.
 */
export function objectHasKeys<T>(obj: unknown, keys: (keyof T)[]): boolean {
  if (!obj) return false;
  if (typeof obj !== 'object') return false;
  if (obj === null) return false;
  return keys.reduce((accumulator, currentKey) => {
    return Object.hasOwn(obj as object, currentKey) && accumulator;
  }, true);
}

export function getKeysOfObject<T extends object>(
  target: T,
  ignoreKeys?: (keyof T)[]
): (keyof T)[] {
  if (!target && target !== null && typeof target !== 'object') return [];
  if (!ignoreKeys) return Object.keys(target).map((v) => v as keyof T);
  return Object.keys(target)
    .map((v) => v as keyof T)
    .filter((e) => !ignoreKeys.includes(e));
}

export function getKeyUniqProps(key: Key | RawKey): KeyUniqProps {
  return {
    algoId: key.algoId,
    exchange: key.exchange,
    instrumentType: key.instrumentType,
    providerShortName: key.providerShortName,
    symbol: key.symbol,
  };
}

export function generateHashForKey(key: RawKey): string {
  const forHashing = Object.entries(getKeyUniqProps(key)).reduce(
    (acc, curr) => {
      return acc + curr[1];
    },
    ''
  );
  return stringToHash(forHashing);
}

export function notNull<T>(value: T | null): value is T {
  return value !== null;
}

export function minutesToMs(minutes: number): number {
  return minutes * 60 * 1000;
}

/**
 * Formats keyUniqProps to readable string. Example result:
 * `TD(NSE)@AUBANK-priceChange`
 */
export function keyUniqPropsToString(keyUniqProps: KeyUniqProps): string {
  return `${keyUniqProps.providerShortName}(${keyUniqProps.exchange})@${keyUniqProps.symbol}-${keyUniqProps.algoId}`;
}

export function filterUndefined<T extends object>(obj: T): T {
  const result = {} as T;
  (Object.keys(obj) as (keyof T)[]).forEach((k) => {
    if (obj[k] !== undefined) result[k] = obj[k];
  });
  return result;
}

export function nullToUndefined<T>(value: T | null | undefined): T | undefined {
  return value === null ? undefined : value;
}
