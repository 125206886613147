<div class="message">
  <div class="content">
    <img
      src="/assets/textures/widget-error.svg"
      width="64px"
      height="64px"
      alt="Error"
    />
    <h3>Error</h3>
    <p class="color-secondary-l3">Something went wrong...</p>
  </div>
  <button class="accent-button" (click)="reloadPage()">Reload</button>
</div>
