import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AlgoLabels,
  AlgoUiResponseItem,
  DataKeyConnectionInfo,
  EndpointRequestData,
  Key,
  AlgoLabelsMap,
  KeyUniqProps,
  RawKey,
  HeatmapItemInfo,
} from '@core/models/meta';
import { map, Observable } from 'rxjs';
import { generateHashForKey } from '@core/utils';
import { ExchangeInfoResponse } from '@core/models/general';
import {
  BrokenKeyApiResponse,
  EndpointApiResponse,
  KeyApiResponse,
  ProviderInfo,
  ProviderInfoMap,
} from '@core/models/api';

/** Meta BE API client */
@Injectable({
  providedIn: 'root',
})
export class MetaApiService {
  constructor(private _http: HttpClient) {}

  public getKeysAll(): Observable<Key[]> {
    return this._http.get<KeyApiResponse[]>('/meta/api/v1/keys').pipe(
      map((keysResp) => keysResp.map((kr) => kr.key)),
      map((rawKeys) => this._patchRawKeys(rawKeys))
    );
  }

  public getKeysHeatmapsAll(): Observable<Key[]> {
    return this._http
      .get<KeyApiResponse[]>('/meta/api/v1/keys?uiType=HEATMAP')
      .pipe(
        map((keysResp) => keysResp.map((kr) => kr.key)),
        map((rawKeys) => this._patchRawKeys(rawKeys))
      );
  }

  public getKey(keyUniqProps: KeyUniqProps): Observable<Key | null> {
    return this.getKeys([keyUniqProps]).pipe(map((keys) => keys[0]));
  }

  public getKeys(keyUniqProps: KeyUniqProps[]): Observable<(Key | null)[]> {
    const restoreKeys$ = this._http
      .post<(KeyApiResponse | BrokenKeyApiResponse)[]>(
        '/meta/api/v1/keys',
        keyUniqProps
      )
      .pipe(
        map((resp) =>
          resp.map((value) => {
            if (value.type === 'ERROR') return null;
            return {
              ...value.key,
              keyUniqPropsHash: generateHashForKey(value.key),
            };
          })
        )
      );

    return restoreKeys$;
  }

  /*
    TO DO: Ask BE to replace batch request with request for single endpoint
    & remove commented code below
  */

  // public getEndpointsData(
  //   endpointRequestData: EndpointRequestData[]
  // ): Observable<DataKeyConnectionInfo[]> {
  //   const url = '/meta/api/v1/endpoints/live';
  //   return this._http.post<EndpointResponse[]>(url, endpointRequestData).pipe(
  //     map((responses) => {
  //       return responses.map((response) => {
  //         if (response.type === 'ERROR') return response;
  //         return {
  //           url: response.endpoint,
  //           token: response.token,
  //         };
  //       });
  //     })
  //   );
  // }

  public getSingleEndpointData(
    endpointRequestData: EndpointRequestData
  ): Observable<DataKeyConnectionInfo> {
    const url = '/meta/api/v1/endpoints/live';
    return this._http
      .post<EndpointApiResponse[]>(url, [endpointRequestData])
      .pipe(
        map((responses) => {
          if (responses.length < 1) {
            throw new Error(
              'Batch request for 1 key gave response array with length = 0'
            );
          }
          if (responses[0].type === 'ERROR') {
            throw new Error(responses[0].error);
          }
          return { url: responses[0].endpoint, token: responses[0].token };
        })
      );
  }

  public getAlgoLabelsMap(): Observable<AlgoLabelsMap> {
    return this._http.get<AlgoUiResponseItem[]>('/meta/api/v1/ui-info').pipe(
      map((items) => this._patchAlgoUi(items)),
      map((items) =>
        Object.fromEntries(items.map((items) => [items.algoId, items]))
      )
    );
  }

  public getExchangeInfo(): Observable<ExchangeInfoResponse[]> {
    return this._http.get<ExchangeInfoResponse[]>('/meta/api/v1/exchanges');
  }

  public getProviderInfo(): Observable<ProviderInfoMap> {
    return this._http
      .get<ProviderInfo[]>('/meta/api/v1/providers')
      .pipe(map((pi) => this._patchProviderInfo(pi)));
  }

  public requestInterview(email: string): Observable<void> {
    return this._http.post<void>('/meta/api/v1/interview-request', { email });
  }

  public getCapitalization(symbol: string): Observable<unknown> {
    return this._http.get<unknown>('/meta/api/v1/capitalization/' + symbol);
  }

  public getHeatmapItems(heatmapName: string): Observable<HeatmapItemInfo[]> {
    return this._http.post<HeatmapItemInfo[]>('/meta/api/v1/heatmaps/items', {
      name: heatmapName,
    });
  }

  private _patchAlgoUi(infoSet: AlgoUiResponseItem[]): AlgoLabels[] {
    return infoSet.map((item) => ({
      ...item,
      algoInfo: {
        ...item.algoInfo,
        shortName: item.algoShortName,
      },
      presets: Object.fromEntries(item.presets.map((p) => [p.presetId, p])),
      views: Object.fromEntries(item.views.map((v) => [v.viewId, v])),
    }));
  }

  private _patchRawKeys(rawKeys: RawKey[]): Key[] {
    return rawKeys.map((rawKey) => ({
      ...rawKey,
      keyUniqPropsHash: generateHashForKey(rawKey),
    }));
  }

  private _patchProviderInfo(info: ProviderInfo[]): ProviderInfoMap {
    return Object.fromEntries(info.map((i) => [i.shortName, i]));
  }
}
