import { LoginData, UserData } from '@core/models/auth';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const authActions = createActionGroup({
  source: 'AUTH',
  events: {
    'Get User Profile Start': emptyProps(),
    'Get User Profile Success': props<{ userData: UserData }>(),
    'Get User Profile Failure': emptyProps(),

    'Login Start': props<{ loginData: LoginData }>(),
    'Login Success': emptyProps(),
    'Login Failure': emptyProps(),
    'Login Reset': emptyProps(),

    'Logout Start': emptyProps(),
    'Logout Success': emptyProps(),
    'Logout Failure': emptyProps(),
  },
});
