import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, defer, from, map, Observable, of, tap } from 'rxjs';

/** Responsible for checking is client has connection to the Internet */
@Injectable({ providedIn: 'root' })
export class ConnectivityService {
  private _http = inject(HttpClient);
  private _pingLastTimestamp = 0;
  private _pingLastResult = false;

  public isOnline(): Observable<boolean> {
    /* onLine == false always mean there is no access to the Internet, but 
       onLine == true not always mean there is connection to the Internet*/
    if (!navigator.onLine) return of(false);
    const now = Date.now();
    if (now - this._pingLastTimestamp > 1000) {
      return this._getPing$();
    }
    return of(this._pingLastResult);
  }

  private _getPing$(): Observable<boolean> {
    return defer(() =>
      from(
        fetch('https://www.google.com/generate_204', { mode: 'no-cors' })
      ).pipe(
        map(() => true),
        catchError(() => of(false)),
        tap((isOnline) => {
          console.log('isOnline', isOnline);
          this._pingLastResult = isOnline;
          this._pingLastTimestamp = Date.now();
        })
      )
    );
  }
}
