<div class="section icon">
  <img [src]="'/assets/icons/notification/' + type + '.svg'" alt="icon" />
</div>
<div class="section body">
  <ng-content></ng-content>
</div>
@if (actionText) {
  <div class="section action">
    <button class="link-button input-small" (click)="clickAction.emit()">
      {{ actionText }}
    </button>
  </div>
}

<button
  class="close-button"
  aria-label="Close notification"
  (click)="clickClose.emit()"
>
  <app-color-svg src="/assets/icons/cross.svg"></app-color-svg>
</button>
