import { createReducer, on } from '@ngrx/store';
import { initialUiState } from './user-interface.state';
import { userInterfaceActions } from './user-interface.actions';

export const userInterfaceReducer = createReducer(
  initialUiState,
  on(userInterfaceActions.loaderOpen, (state) => ({
    ...state,
    globalLoader: true,
  })),
  on(userInterfaceActions.loaderClose, (state) => ({
    ...state,
    globalLoader: false,
  }))
);
