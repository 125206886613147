import { MS_IN_DAY, MS_IN_MINUTE } from '@core/constants/common';
import {
  ExchangeInfo,
  ExchangeInfoSchedule,
  ScheduleItem,
  ScheduleState,
} from '@core/models/general';
import { notNull } from '@core/utils';
import { addWeeks, startOfWeek } from 'date-fns';
import { Observable, Subject, merge } from 'rxjs';

/** One of stages of creation of {@link ScheduleState|ScheduleState} */
type SchedulePreState = Pick<ScheduleState, 'currentState' | 'stateStartDate'>;

/** This class responsible for calculating real dates from given schedule */
export class ExchangeActiveSchedule {
  private _statesArr: ScheduleState[] = [];
  private _scheduleEvents$ = new Subject<ScheduleState>();

  /**
   * Minimum time between events. Events with closer dates will be filtered.
   *
   * Some schedule entries can be closing at 23:59 and opening 00:00 next day.
   * This is not actually market close, just limitation of schedule format. We
   * need to avoid closing widget for just 1 minute.
   */
  private readonly DELTA_THRESHOLD_MS = MS_IN_MINUTE * 2;

  constructor(private _info: ExchangeInfo) {
    if (!_info) throw new Error('No exchange info to create schedule');
    this._refillEventArray();
  }

  /** Check is there need to be update and send to stream state updates */
  public tick(): void {
    if (this._info.weekSchedule.schedule === '24/7') return;
    const currentState = this._statesArr.at(-1);
    if (
      !currentState ||
      currentState.nextEventExpectedDate.getTime() > Date.now()
    ) {
      /* There is no states or current state not expired yet */
      return;
    }

    this._statesArr.pop();

    if (this._statesArr.length < 1) this._refillEventArray();

    const newState = this._statesArr.at(-1);
    if (!newState) {
      throw new Error('[ACTIVE SCHEDULE]: events arr were not refilled');
    }

    this._scheduleEvents$.next(newState);
  }

  public getStateStream(): Observable<ScheduleState> {
    return merge(this._scheduleEvents$, this._getStateStreamSnapshot());
  }

  /** IDK how to make it better. State in widget wrapper updates only this
   *  way (if no events from sockets) */
  private _getStateStreamSnapshot(): Observable<ScheduleState> {
    const lastState = this._statesArr.at(-1);
    if (!lastState) return this._scheduleEvents$;
    const stateForMacroTask$ = new Subject<ScheduleState>();
    setTimeout(() => {
      if (lastState) stateForMacroTask$.next(lastState);
    });
    return stateForMacroTask$;
  }

  /** Get schedule in string form */
  public getScheduleString(
    schedule: ExchangeInfoSchedule = this._info.weekSchedule.schedule
  ): string {
    /* TO-DO: ask for desired schedule format */
    console.debug(schedule);
    return '';
  }

  /** Clear current event array & generate new */
  private _refillEventArray() {
    /**
     * Supposed to contain events for current week, but actually contains
     * event for previous week. So we need events for 2 next week just in case.
     *
     * Note: if you want to fix this behavior, test it with all time zones.
     */
    const weekStates = this._convertScheduleToEventArray();

    const generatedStatesArr: SchedulePreState[] = [
      /* Fill events for 3 weeks */
      ...weekStates,
      ...weekStates.map((event) => ({
        ...event,
        stateStartDate: addWeeks(event.stateStartDate, 1),
      })),
      ...weekStates.map((event) => ({
        ...event,
        stateStartDate: addWeeks(event.stateStartDate, 2),
      })),
    ];

    this._statesArr = this._filterClosestEvents(generatedStatesArr)
      .map((item, index, arr) => {
        const nextEventDate = arr[index + 1]
          ? arr[index + 1].stateStartDate
          : addWeeks(arr[0].stateStartDate, 1);
        return { ...item, nextEventExpectedDate: nextEventDate };
      })
      /* Throw out all expired events */
      .filter((e) => e.nextEventExpectedDate.getTime() > Date.now())
      .reverse();
  }

  /** Convert schedule to array with state changes events */
  private _convertScheduleToEventArray(
    schedule: ExchangeInfoSchedule = this._info.weekSchedule.schedule
  ): SchedulePreState[] {
    /* No events of closing or opening */
    if (schedule === '24/7') return [];

    /** Timestamp of today date, aligned to zero UTC time */
    const today = new Date();
    const startOfCurrentWeek = startOfWeek(today);
    const eventArray: SchedulePreState[] = schedule
      .map((day, dayIndex) => {
        if (day === null) return null;

        return day
          .map((openClosePair) => {
            return this._getPreStatePair(
              openClosePair,
              startOfCurrentWeek,
              dayIndex
            );
          })
          .flat(1);
      })
      .filter(notNull)
      .flat(1);

    return eventArray;
  }

  /** Convert just hours and minutes of opening & closing to `Date` objects */
  private _getPreStatePair(
    openClosePair: ScheduleItem,
    /** Info to base new Date on */
    weekStartDate: Date,
    dayIndex: number
  ): SchedulePreState[] {
    const DAY_OFFSET =
      (weekStartDate.getDay() - weekStartDate.getUTCDay()) * MS_IN_DAY;

    const currentDayDate = (hour: number, minute: number): Date => {
      const weekStartDateCopy = new Date(weekStartDate.getTime());
      return new Date(
        weekStartDateCopy.setUTCHours(hour, minute) +
          dayIndex * MS_IN_DAY +
          DAY_OFFSET
      );
    };

    return [
      {
        currentState: 'OPEN',
        stateStartDate: currentDayDate(
          openClosePair.openingTime.hour,
          openClosePair.openingTime.minute
        ),
      },
      {
        currentState: 'CLOSE',
        stateStartDate: currentDayDate(
          openClosePair.closingTime.hour,
          openClosePair.closingTime.minute
        ),
      },
    ];
  }

  /**
   * Detects & filters events have close neighbors (closer than
   * {@link DELTA_THRESHOLD_MS| this.DELTA_THRESHOLD_MS}) in order to avoid
   * situation like *close in 23:59, open in 00:00*.
   */
  private _filterClosestEvents(
    eventArr: SchedulePreState[]
  ): SchedulePreState[] {
    const result: SchedulePreState[] = [];

    eventArr.forEach((item, index, array) => {
      const prevItem = array[index - 1];
      const nextItem = array[index + 1];

      const closeToPrev =
        prevItem /* not close if not exist */ &&
        Math.abs(
          prevItem.stateStartDate.getTime() - item.stateStartDate.getTime()
        ) < this.DELTA_THRESHOLD_MS;

      const closeToNext =
        nextItem /* not close if not exist */ &&
        Math.abs(
          nextItem.stateStartDate.getTime() - item.stateStartDate.getTime()
        ) < this.DELTA_THRESHOLD_MS;

      if (closeToPrev) return;
      if (closeToNext) return;
      result.push(item);
    });

    return result;
  }
}
