import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-color-svg',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '',
  styleUrls: ['./color-svg.component.scss'],
  host: {
    role: 'img',
  },
})
export class ColorSvgComponent {
  @Input() public src = '';

  @HostBinding('style.-webkit-mask-image')
  get mask() {
    return `url(${this.src})`;
  }
}
