import {
  WorkspaceMetadata,
  WorkspaceModeName,
} from '@features/workspace/models/workspace.models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const workspacesActions = createActionGroup({
  source: 'WORKSPACES',
  events: {
    'Load Metadata All Start': emptyProps(),
    'Load Metadata All Success': props<{ metadata: WorkspaceMetadata[] }>(),
    'Load Metadata All Failure': props<{ errorMsg?: string }>(),

    'Set Mode': props<{ mode: WorkspaceModeName }>(),
  },
});
