import { animate, style, transition, trigger } from '@angular/animations';
import { fadeAnimation } from '@core/animations/common.animations';

export const toastNotificationsAnimations = [
  trigger('moveAnimation', [
    transition(':enter', [
      style({
        opacity: 0,
        transform: 'scale(0.9, 0.5)',
        marginTop: '-24px',
      }),
      animate(
        '150ms cubic-bezier(0.000, 0.500, 0.5, 1.000)',
        style({
          opacity: 1,
          transform: 'scale(1)',
          marginTop: '0',
          marginBottom: '0',
        })
      ),
    ]),
    transition(':leave', [
      style({
        opacity: 1,
        transform: 'scale(1)',
        marginTop: '0',
      }),
      animate(
        '120ms cubic-bezier(0.000, 0.500, 0.5, 1.000)',
        style({
          opacity: 0,
          transform: 'scale(0.9, 0.5)',
          marginTop: '-36px',
        })
      ),
    ]),
  ]),
  fadeAnimation,
];
