import { inject, Injectable } from '@angular/core';
import {
  AnalyticsButtonLocation,
  AnalyticsButtonName,
  AnalyticsEventName,
  AppErrorType,
  WidgetErrorType,
} from '@core/constants/analytics';
import {
  LS_IGNORE_ANALYTICS_EVENTS,
  LS_LOG_ANALYTICS_EVENTS,
} from '@core/constants/debug';
import { InstrumentLabels } from '@core/models/widget';
import { environment } from 'src/environments/environment';
import { ConnectivityService } from './connectivity.service';
import { map, Observable, of } from 'rxjs';

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

type ReportParams = {
  provider?: string;
  alias?: string;
  algo?: string;
  preset?: string;
  error_type?: WidgetErrorType | AppErrorType;
  user_id?: string;
  button_name?: string;
  button_location?: string;
  'gtm.start'?: unknown;
};

/**
 * This service is responsible for initialization of Google Tag Manager script and
 * dispatching events.
 */
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private _userId?: string;
  private _isDebugMode = localStorage.getItem(LS_LOG_ANALYTICS_EVENTS) !== null;
  private _shouldBlockEvents =
    localStorage.getItem(LS_IGNORE_ANALYTICS_EVENTS) !== null;
  private _connectivityService = inject(ConnectivityService);

  public init() {
    this._initGtm();
  }

  public report(
    eventName: AnalyticsEventName,
    params: ReportParams = {}
  ): void {
    if (this._shouldBlockEvents) return;

    this._patchParams(eventName, params).subscribe((patchedParams) => {
      const gaEvent: Record<string, unknown> = {
        ...patchedParams,
        event: eventName,
        user_id: this._userId,
      };
      if (this._isDebugMode) {
        console.log(
          `[ANALYTICS]: dispatch - \x1b[1m\x1b[33m${eventName}`,
          gaEvent
        );
      }

      window.dataLayer.push(gaEvent);
    });
  }

  /** Replace connection_error with network_error if no connection to external services */
  private _patchParams(
    eventName: AnalyticsEventName,
    params: ReportParams
  ): Observable<ReportParams> {
    if (eventName !== 'widget_error') return of(params);
    if (params.error_type !== 'connection_error') return of(params);
    return this._connectivityService.isOnline().pipe(
      map((isOnline) => {
        if (!('error_type' in params)) return params;
        const newParams = { ...params };
        newParams.error_type = isOnline ? 'connection_error' : 'network_error';
        return newParams;
      })
    );
  }

  public reportClick(
    buttonName: AnalyticsButtonName,
    buttonLocation: AnalyticsButtonLocation
  ): void {
    this.report('button_click', {
      button_name: buttonName,
      button_location: buttonLocation,
    });
  }

  public reportWidget(
    eventName: AnalyticsEventName,
    labels: InstrumentLabels,
    additional?: {
      threshold?: number;
      preset?: string;
      volume?: number;
      error_type?: WidgetErrorType;
    }
  ): void {
    this.report(eventName, {
      provider: labels.provider,
      alias: labels.alias,
      algo: labels.algo,
      ...additional,
    });
  }

  public setUserId(userId: string) {
    this._userId = userId;
  }

  private _initGtm(): void {
    window.dataLayer = window.dataLayer || [];

    if (!environment.gtagToken) {
      throw new Error("GTM script wasn't injected. Token not found.");
    }

    this.report('gtm.js', { 'gtm.start': new Date().getTime() });

    const baseUrl = 'https://www.googletagmanager.com/gtm.js?id=';
    const url = baseUrl + environment.gtagToken + '&l=dataLayer';
    this.injectScript(url);
  }

  private injectScript(url: string): void {
    const script = document.createElement('script');
    const head = document.getElementsByTagName('head')[0];

    script.async = true;
    script.src = url;
    head.appendChild(script);
  }
}
