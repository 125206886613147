import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeyUniqProps } from '@core/models/meta';
import { getKeyUniqProps } from '@core/utils';
import {
  BasicWidget,
  SaveWidgetSettings,
  WidgetCloudSaveData,
  WidgetRestoreData,
} from '@core/models/widget';
import { BasicWorkspace } from '@features/workspace/classes/basic-workspace.class';
import { WorkspaceMetadata } from '@features/workspace/models/workspace.models';
import { Observable, map } from 'rxjs';
import { SettingsConverterService } from '../settings-converter.service';

/** Final data structure that can be put on the server to update workspace state */
type WorkspaceSaveData = {
  name: string;
  /** Widget save data */
  workspaceItems: WorkspaceSaveItem[];
};

/** Represents single widget */
type WorkspaceSaveItem = {
  keyUniqueProperties: KeyUniqProps;
  widgetSettings: SaveWidgetSettings;
};

@Injectable({
  providedIn: 'root',
})
export class WorkspacesApiService {
  constructor(
    private _http: HttpClient,
    private _converter: SettingsConverterService
  ) {}

  public getWorkspacesMetadata(): Observable<WorkspaceMetadata[]> {
    return this._http.get<WorkspaceMetadata[]>('/meta/api/v1/workspaces');
  }

  public getWorkspacesTemplatesAll(): Observable<WorkspaceMetadata[]> {
    return this._http.get<WorkspaceMetadata[]>(
      '/meta/api/v1/workspaces/templates'
    );
  }

  public getWorkspacesTemplatesDefaults(): Observable<WorkspaceMetadata[]> {
    return this._http.get<WorkspaceMetadata[]>(
      '/meta/api/v1/workspaces/templates/defaults'
    );
  }

  public getWorkspaceTemplateById(
    templateId: string
  ): Observable<WidgetRestoreData[]> {
    return this._http
      .get<WorkspaceSaveItem[]>(
        '/meta/api/v1/workspaces/templates/' + templateId
      )
      .pipe(
        map((workspaceItemsArr) =>
          workspaceItemsArr.map((wsItem) => {
            return {
              keyUniqProps: wsItem.keyUniqueProperties,
              settings: wsItem.widgetSettings,
            };
          })
        )
      );
  }

  public getWorkspaceSavesByWsId(
    workspaceId: string
  ): Observable<WidgetRestoreData[]> {
    return this._http
      .get<WorkspaceSaveItem[]>('/meta/api/v1/workspaces/' + workspaceId)
      .pipe(
        map((workspaceItemsArr) =>
          workspaceItemsArr.map((wsItem) => {
            return {
              keyUniqProps: wsItem.keyUniqueProperties,
              settings: wsItem.widgetSettings,
            };
          })
        )
      );
  }

  public createWorkspace(name: string, saves: WidgetRestoreData[] = []) {
    const saveItems: WorkspaceSaveItem[] = saves.map((save) => ({
      keyUniqueProperties: save.keyUniqProps,
      widgetSettings: save.settings,
    }));
    const newWorkspace: WorkspaceSaveData = {
      name: name,
      workspaceItems: saveItems,
    };
    return this._http.post<string>('/meta/api/v1/workspaces', newWorkspace);
  }

  public updateWorkspaceByInstance(
    workspace: BasicWorkspace
  ): Observable<void> {
    return this._http.put<void>(
      '/meta/api/v1/workspaces/' + workspace.workspaceId,
      this._convertWorkspaceToSaveData(workspace)
    );
  }

  public updateWorkspace(
    workspaceId: string,
    name: string,
    saves: WidgetRestoreData[]
  ): Observable<void> {
    const saveItems: WorkspaceSaveItem[] = saves.map((save) => ({
      keyUniqueProperties: save.keyUniqProps,
      widgetSettings: save.settings,
    }));
    const saveData: WorkspaceSaveData = {
      name,
      workspaceItems: saveItems,
    };

    return this._http.put<void>(
      '/meta/api/v1/workspaces/' + workspaceId,
      saveData
    );
  }

  public deleteWorkspace(workspaceId: string): Observable<void> {
    return this._http.delete<void>('/meta/api/v1/workspaces/' + workspaceId);
  }

  private _convertWorkspaceToSaveData(
    workspace: BasicWorkspace
  ): WorkspaceSaveData {
    return {
      name: workspace.name,
      workspaceItems: workspace
        .getWidgetsAll()
        .map((w) => this._getWidgetClodSaveData(w)),
    };
  }

  private _getWidgetClodSaveData(widget: BasicWidget): WidgetCloudSaveData {
    return {
      keyUniqueProperties: getKeyUniqProps(widget.key),
      widgetSettings: this._converter.toSaveSettings(widget.settings),
    };
  }
}
