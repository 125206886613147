import { Injectable } from '@angular/core';
import {
  PromoNotificationInfo,
  CompactNotificationInfo,
  CompactNotificationParams,
  PromoNotificationParams,
} from './toast-notifications.model';
import { BehaviorSubject, Subject, combineLatest, map, of } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { SoundPlayerService } from '@core/services/sound-player.service';
import { LS_DISABLE_NOTIFICATIONS } from '@core/constants/debug';

@Injectable({
  providedIn: 'root',
})
export class ToastNotificationService {
  private readonly LIMIT_COMPACT = 2;
  private readonly LIMIT_PROMO = 1;
  private _promoNotifications$ = new BehaviorSubject<PromoNotificationInfo[]>(
    []
  );
  public promoNotifications$ = this._promoNotifications$.asObservable();

  private _notifications$ = new BehaviorSubject<CompactNotificationInfo[]>([]);
  public notifications$ = this._notifications$.asObservable();

  private _shouldIgnoreNotifications = this._detectLocalStorageMod();

  private _soundNotificationAllowed$ = new BehaviorSubject<boolean>(false);
  public soundNotification$ = this._shouldIgnoreNotifications
    ? of(false)
    : combineLatest([
        this._soundNotificationAllowed$,
        this._player.locked$,
      ]).pipe(map(([enabled, locked]) => enabled && locked));

  public shouldHideNotifications$ = new Subject<boolean>();

  constructor(private _player: SoundPlayerService) {}

  public hideNotifications() {
    this.shouldHideNotifications$.next(true);
  }

  public unhideNotifications() {
    this.shouldHideNotifications$.next(false);
  }

  public allowSoundNotifications() {
    this._soundNotificationAllowed$.next(true);
  }

  public showCompactNotification(params: CompactNotificationParams): string {
    if (this._shouldIgnoreNotifications) return '';
    const id = uuid();

    if (params.lifeTime && (params.lifeTime === 0 || params.lifeTime < -1)) {
      throw new Error('Incorrect notification lifetime - ' + params.lifeTime);
    }

    if (params.lifeTime !== -1) {
      const finalLifetime = params.lifeTime ?? 5000;
      setTimeout(() => {
        this.close(id);
      }, finalLifetime);
    }

    if (this._notifications$.value.length >= this.LIMIT_COMPACT) {
      this._notifications$.next([...this._notifications$.value.slice(1)]);
    }

    setTimeout(() => {
      this._notifications$.next([
        ...this._notifications$.value,
        { ...params, id },
      ]);
    });

    return id;
  }

  public showPromoNotification(params: PromoNotificationParams): string {
    if (this._shouldIgnoreNotifications) return '';
    if (params.lifeTime === 0) {
      throw new Error('Notification lifetime should be > 0');
    }

    const finalLifeTime = params.lifeTime ?? -1;
    if (finalLifeTime !== -1) {
      setTimeout(() => {
        this.close(id);
      }, finalLifeTime);
    }

    const id = uuid();

    if (this._promoNotifications$.value.length >= this.LIMIT_PROMO) {
      this._promoNotifications$.next([
        ...this._promoNotifications$.value.slice(1),
      ]);
    }

    setTimeout(() => {
      this._promoNotifications$.next([
        ...this._promoNotifications$.value,
        { ...params, id },
      ]);
    });

    return id;
  }

  public close(id: string): void {
    this._promoNotifications$.next([
      ...this._promoNotifications$.value.filter((n) => n.id !== id),
    ]);
    this._notifications$.next([
      ...this._notifications$.value.filter((n) => n.id !== id),
    ]);
  }

  private _detectLocalStorageMod() {
    return !!localStorage.getItem(LS_DISABLE_NOTIFICATIONS);
  }
}
