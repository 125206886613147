import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const listAnimation = trigger('listAnimation', [
  transition(':enter, * => 0, * => -1', []),
  transition(':increment', [
    query(
      ':enter',
      [
        style({ opacity: 0, transform: 'scale(1.1)' }),
        stagger(200, [
          animate(
            '200ms ease-out',
            style({ opacity: 1, transform: 'scale(1)' })
          ),
        ]),
      ],
      { optional: true }
    ),
  ]),
  transition(':decrement', [
    style({ opacity: 1, transform: 'scale(1)' }),
    query(
      ':enter',
      [
        stagger(20, [
          animate(
            '200ms ease-out',
            style({ opacity: 0, transform: 'scale(0.9)' })
          ),
        ]),
      ],
      { optional: true }
    ),
    query(':leave', [
      stagger(20, [
        animate(
          '200ms ease-out',
          style({ opacity: 0, transform: 'scale(0.9)' })
        ),
      ]),
    ]),
  ]),
]);

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate('150ms ease-out', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    style({
      opacity: 1,
    }),
    animate('150ms ease-out', style({ opacity: 0 })),
  ]),
]);
