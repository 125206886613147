import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { authActions } from './auth.actions';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';
import { AuthService } from '@core/services/api/auth.service';
import { Store } from '@ngrx/store';
import { userInterfaceActions } from '../user-interface/user-interface.actions';
import { ToastNotificationService } from '@features/toast-notification/toast-notification.service';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store,
    private toast: ToastNotificationService
  ) {}

  getUserProfileStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.getUserProfileStart),
      exhaustMap(() => {
        return this.authService.getUser().pipe(
          map((response) => {
            return authActions.getUserProfileSuccess({
              userData: response.user,
            });
          }),
          catchError(() => {
            return of(authActions.getUserProfileFailure());
          })
        );
      })
    )
  );

  loginStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginStart),
      exhaustMap((action) => {
        this.store.dispatch(userInterfaceActions.loaderOpen());
        return this.authService.login(action.loginData).pipe(
          map(() => {
            return authActions.loginSuccess();
          }),
          tap({
            next: () => this.store.dispatch(userInterfaceActions.loaderClose()),
            error: () =>
              this.store.dispatch(userInterfaceActions.loaderClose()),
          }),
          catchError((err) => {
            console.error(err);
            if (err.status !== 403) {
              this.toast.showCompactNotification({
                body: 'Something went wrong...',
                type: 'error',
              });
            }
            return of(authActions.loginFailure());
          })
        );
      })
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginSuccess),
      exhaustMap(() => {
        location.reload();
        return of({ type: 'EMPTY' });
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logoutStart),
      exhaustMap(() => {
        this.store.dispatch(userInterfaceActions.loaderOpen());
        return this.authService.logout().pipe(
          map(() => {
            location.reload();
            return authActions.logoutSuccess();
          }),
          tap({
            next: () => this.store.dispatch(userInterfaceActions.loaderClose()),
            error: () =>
              this.store.dispatch(userInterfaceActions.loaderClose()),
          }),
          catchError(() => {
            return of(authActions.logoutFailure);
          })
        );
      })
    )
  );
}
