import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@core/configs/app.config';
import { WIDGET_EXTRA_SETTINGS_DATA_TYPE_MAP } from '@core/constants/widget-settings';
import { AppConfig } from '@core/models/config';
import {
  ExtraWidgetSettings,
  SaveWidgetSettings,
  WidgetSettings,
} from '@core/models/widget';
import { AlgoDataType } from '@core/models/algo';
import { MetaService } from './api/meta.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsConverterService {
  constructor(
    @Inject(APP_CONFIG) private _appConfig: AppConfig,
    private _meta: MetaService
  ) {}

  /** Converts internal settings to format for saving */
  public toSaveSettings(widgetSettings: WidgetSettings): SaveWidgetSettings {
    const misc = JSON.stringify(widgetSettings.misc);
    return { ...widgetSettings, misc };
  }

  /** Converts settings save to internal format */
  public toSettings(
    widgetSettings: SaveWidgetSettings,
    algoId?: string
  ): WidgetSettings {
    let parsedExtraSettings: ExtraWidgetSettings = {};
    if (widgetSettings.misc == null) {
      return { ...widgetSettings, misc: parsedExtraSettings };
    }

    try {
      parsedExtraSettings = JSON.parse(widgetSettings.misc);
      if (parsedExtraSettings === null) parsedExtraSettings = {};
      parsedExtraSettings = this.patchExtraSettings(
        parsedExtraSettings,
        algoId
      );
    } catch {
      console.error("Can't parse this misc property - " + widgetSettings.misc);
    }

    return { ...widgetSettings, misc: parsedExtraSettings };
  }

  /**
   * This method patches extra settings for a specific algorithm. If algorithm
   * is unknown, it returns empty object.
   *
   * @param {unknown} extraSettings - Unreliable extra settings .
   * @param {string} algoId - The ID of the algorithm for which the settings are to be patched.
   * @param {Required<ExtraWidgetSettings>} - Fallback values.
   *
   * @returns {ExtraWidgetSettings} - The patched extra settings for the algorithm.
   */
  public patchExtraSettings(
    extraSettings: unknown,
    algoId?: string,
    defaults = this._appConfig.widget.settings.defaultsExtra
  ): ExtraWidgetSettings {
    const resultExtraSettings: ExtraWidgetSettings = {};
    /** Params for specific algo */
    const settingsForAlgoId =
      WIDGET_EXTRA_SETTINGS_DATA_TYPE_MAP[
        this._getAlgoDataType(algoId) ?? 'DEFAULT'
      ];

    if (!settingsForAlgoId) return resultExtraSettings;
    if (!extraSettings) return resultExtraSettings;
    if (typeof extraSettings !== 'object') return resultExtraSettings;

    settingsForAlgoId.forEach((setting) =>
      this._patchSingleExtraSetting(
        setting,
        extraSettings,
        resultExtraSettings,
        defaults
      )
    );

    return resultExtraSettings;
  }

  private _patchSingleExtraSetting<K extends keyof ExtraWidgetSettings>(
    key: K,
    input: object,
    output: ExtraWidgetSettings,
    defaults: Required<ExtraWidgetSettings>
  ): void {
    const DEFAULT_VALUE = defaults[key];

    output[key] = DEFAULT_VALUE;
    if (
      key in input &&
      typeof input[key as keyof typeof input] === typeof DEFAULT_VALUE
    ) {
      output[key] = input[key as keyof typeof input] as ExtraWidgetSettings[K];
    }
  }

  private _getAlgoDataType(algoId?: string): AlgoDataType | null {
    if (!algoId) return null;
    const algoLabels = this._meta.getAlgoLabels(algoId);
    if (!algoLabels) return null;
    const view = Object.entries(algoLabels.views)[0][1];
    if (!view) return null;
    return view.widgetInfo.dataType;
  }
}
