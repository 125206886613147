import { InjectionToken } from '@angular/core';
import { WidgetAppConfig } from '@core/models/config';
import { widgetSettingsAppConfig } from './widget-settings-app.config';

export const widgetAppConfig: WidgetAppConfig = {
  reconnectDelay: 1000,
  reconnectMultiplier: 2,
  defaultIframeSize: [184, 230],
  settings: widgetSettingsAppConfig,
};
export const APP_WIDGET_CONFIG = new InjectionToken<WidgetAppConfig>(
  'APP_WIDGET_CONFIG'
);
