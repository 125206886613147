type SoundCollection = Record<(typeof soundNames)[number], string>;
type SoundSet = {
  positive: SoundCollection;
  negative: SoundCollection;
};

const BASE_URL = '/assets/sounds/';

export const soundNames = [
  '1',
  '2',
  '3',
  'Drums',
  '101',
  '102',
  'Blip',
  'Dice',
  'Magnet',
  'Slide',
  'Space',
] as const;

export const soundsUrl: SoundSet = {
  positive: {
    Drums: BASE_URL + 'drumsP.wav',
    '101': BASE_URL + '101P.wav',
    '102': BASE_URL + '102P.wav',
    '1': BASE_URL + '1P.wav',
    '2': BASE_URL + '2P.wav',
    '3': BASE_URL + '3P.wav',
    Blip: BASE_URL + 'blipP.wav',
    Dice: BASE_URL + 'diceP.wav',
    Magnet: BASE_URL + 'magnetP.wav',
    Slide: BASE_URL + 'slideP.wav',
    Space: BASE_URL + 'spaceP.wav',
  },
  negative: {
    Drums: BASE_URL + 'drumsN.wav',
    '101': BASE_URL + '101N.wav',
    '102': BASE_URL + '102N.wav',
    '1': BASE_URL + '1N.wav',
    '2': BASE_URL + '2N.wav',
    '3': BASE_URL + '3N.wav',
    Blip: BASE_URL + 'blipN.wav',
    Dice: BASE_URL + 'diceN.wav',
    Magnet: BASE_URL + 'magnetN.wav',
    Slide: BASE_URL + 'slideN.wav',
    Space: BASE_URL + 'spaceN.wav',
  },
};
