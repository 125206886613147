import { ActionReducerMap } from '@ngrx/store';
import { AppState } from './app.state';
import { AUTH_STATE_NAME } from './auth/auth.state';
import { authReducer } from './auth/auth.reducer';
import { USER_INTERFACE_STATE_NAME } from './user-interface/user-interface.state';
import { userInterfaceReducer } from './user-interface/user-interface.reducer';
import { WORKSPACES_STATE_NAME } from './workspaces/workspaces.state';
import { workspacesReducer } from './workspaces/workspaces.reducer';

export const appReducers: ActionReducerMap<AppState> = {
  [AUTH_STATE_NAME]: authReducer,
  [USER_INTERFACE_STATE_NAME]: userInterfaceReducer,
  [WORKSPACES_STATE_NAME]: workspacesReducer,
};
