import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AnalyticsService } from '@core/services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(private analytics: AnalyticsService) {
    this.analytics.init();
  }
}
