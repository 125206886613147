import { Injectable } from '@angular/core';
import { Observable, Subject, filter, fromEvent, map, merge, tap } from 'rxjs';

/**
 * This service provides ticks generators that will be in sync with other
 * app instances (to reach sync timer updates)
 */
@Injectable({
  providedIn: 'root',
})
export class TickService {
  private _generators$ = {
    1000: new Subject<void>(),
  };

  public isPageVisible = true;
  public isPageVisible$: Observable<boolean> = this._getPageVisibilityStream();
  public pageLoseFocus$ = this.isPageVisible$.pipe(
    filter((isVisible) => !isVisible)
  );
  public pageGainFocus$ = this.isPageVisible$.pipe(
    filter((isVisible) => isVisible)
  );

  /** Stream of ticks with interval 1000 ms */
  get generator1000$() {
    return this._generators$[1000].asObservable();
  }
  /** Stream of ticks with interval 1000 ms, but stops when tab is inactive */
  get generator1000uiOnly$() {
    return merge(
      this._generators$[1000].pipe(filter(() => this.isPageVisible)),
      this.isPageVisible$.pipe(filter((isVisible) => !isVisible))
    );
  }

  constructor() {
    this._startGenerators();
    this._watchForPageVisibility();
  }

  private _startGenerators() {
    /** Required in order to sync this timers starts (at least on visual part) on
     * separate windows */
    const msToNextSecond = Date.now() % 1000;
    setTimeout(() => {
      this._tick1s();
    }, msToNextSecond);
  }

  private _tick1s() {
    this._generators$[1000].next();
    setTimeout(() => this._tick1s(), 1000 - (Date.now() % 1000));
  }

  private _watchForPageVisibility() {
    this.isPageVisible$
      .pipe(tap((isVisible) => (this.isPageVisible = isVisible)))
      .subscribe();
  }

  private _getPageVisibilityStream(): Observable<boolean> {
    return fromEvent(document, 'event').pipe(
      map(() => document.visibilityState === 'visible')
    );
  }
}
