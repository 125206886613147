import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { ColorSvgComponent } from '@shared/components/color-svg/color-svg.component';

@Component({
  selector: 'app-notification-card-promo',
  standalone: true,
  imports: [ColorSvgComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './notification-card-promo.component.html',
  styleUrl: './notification-card-promo.component.scss',
})
export class NotificationCardPromoComponent {
  @Input() public actionText?: string;
  @Output() public clickAction = new EventEmitter<void>();
  @Output() public clickClose = new EventEmitter<void>();

  @HostListener('click') private _handleClick() {
    this.clickAction.emit();
  }
}
