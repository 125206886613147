import { Injectable } from '@angular/core';
import {
  AlgoLabels,
  DataKeyConnectionInfo,
  EndpointRequestData,
  Key,
  AlgoLabelsMap,
  KeyUniqProps,
  HeatmapItemInfo,
} from '@core/models/meta';
import { catchError, combineLatest, map, Observable, of, tap } from 'rxjs';
import { MemoizedObservable } from '@core/classes/memoized-observable.class';
import { ExchangeInfo, ExchangeInfoResponse } from '@core/models/general';
import { ExchangeInfoService } from '../exchange-info.service';
import { ExchangeActiveSchedule } from '@core/classes/exchange-active-schedule.class';
import { AnalyticsService } from '../analytics.service';
import { MS_IN_HOUR, MS_IN_MINUTE } from '@core/constants/common';
import { MetaApiService } from './meta-api.service';
import { ProviderInfo, ProviderInfoMap } from '@core/models/api';
import { Router } from '@angular/router';

/** Meta BE API client */
@Injectable({
  providedIn: 'root',
})
export class MetaService {
  private _keysAll = new MemoizedObservable<Key[]>(this._metaApi.getKeysAll(), {
    cacheLifetime: MS_IN_MINUTE * 5,
  });
  private _keysHeatmapsAll = new MemoizedObservable<Key[]>(
    this._metaApi.getKeysHeatmapsAll(),
    {
      cacheLifetime: MS_IN_MINUTE * 5,
    }
  );
  private _algoLabelsList = new MemoizedObservable<AlgoLabelsMap>(
    this._metaApi.getAlgoLabelsMap(),
    { cacheLifetime: MS_IN_HOUR * 12 }
  );
  private _exchangeInfo = new MemoizedObservable<ExchangeInfoResponse[]>(
    this._metaApi
      .getExchangeInfo()
      .pipe(tap((info) => this._exchangeInfoService.updateExchangeInfo(info))),
    { cacheLifetime: MS_IN_HOUR * 12 }
  );
  private _providerInfo = new MemoizedObservable<ProviderInfoMap>(
    this._metaApi.getProviderInfo(),
    { cacheLifetime: MS_IN_HOUR * 12 }
  );
  public outerReferrer: string | null = null;
  public isEmbedding: boolean = false;

  constructor(
    private _metaApi: MetaApiService,
    private _exchangeInfoService: ExchangeInfoService,
    private _analytics: AnalyticsService,
    private _router: Router
  ) {}

  public getKeysAll(): Observable<Key[]> {
    return this._ensureCrucialDataLoaded(this._keysAll.getStream());
  }

  public getKeysHeatmapsAll(): Observable<Key[]> {
    return this._ensureCrucialDataLoaded(this._keysHeatmapsAll.getStream());
  }

  public getKey(keyUniqProps: KeyUniqProps): Observable<Key | null> {
    return this._metaApi.getKey(keyUniqProps);
  }

  public getKeys(keyUniqProps: KeyUniqProps[]): Observable<(Key | null)[]> {
    return this._ensureCrucialDataLoaded(this._metaApi.getKeys(keyUniqProps));
  }

  public getAlgoLabels(algoId: string): AlgoLabels | null {
    const currentAlgoLabelsList = this._algoLabelsList.getValue();
    if (!currentAlgoLabelsList)
      throw new Error('Trying to access algo labels before keys load');
    const algoLabels = currentAlgoLabelsList[algoId];
    if (!algoLabels) return null;
    return algoLabels;
  }

  public getExchangeInfo(exchangeShortName: string): ExchangeInfo {
    const info = this._exchangeInfoService.getExchangeInfo(exchangeShortName);
    if (!info) {
      this._analytics.report('app_error', { error_type: 'no_exchange_info' });
      throw new Error('There is no info for exchange ' + exchangeShortName);
    }
    return info;
  }

  public getProviderInfo(providerId: string): ProviderInfo | null {
    const infoMap = this._providerInfo.getValue();
    if (!infoMap) {
      console.warn('No provider info loaded yet');
      return null;
    }
    const info = infoMap[providerId];
    if (!info) {
      console.warn('No provider info for providerId', providerId);
      return null;
    }
    return info;
  }

  public getExchangeSchedule(
    exchangeShortName: string
  ): ExchangeActiveSchedule {
    const schedule =
      this._exchangeInfoService.getExchangeSchedule(exchangeShortName);
    if (!schedule) {
      throw new Error('There is no schedule for exchange ' + exchangeShortName);
    }
    return schedule;
  }

  public getSingleEndpointData(
    endpointRequestData: EndpointRequestData
  ): Observable<DataKeyConnectionInfo> {
    return this._metaApi.getSingleEndpointData({
      ...endpointRequestData,
      'external-refer': this.outerReferrer,
    });
  }

  public requestInterview(email: string): Observable<void> {
    return this._metaApi.requestInterview(email);
  }

  public getCapitalization(symbol: string): Observable<unknown> {
    return this._metaApi.getCapitalization(symbol);
  }

  public getHeatmapItems(heatmapName: string): Observable<HeatmapItemInfo[]> {
    return this._metaApi.getHeatmapItems(heatmapName);
  }

  /** algoLabels & exchangeInfo are crucial data for widgets */
  private _ensureCrucialDataLoaded<T>(obs$: Observable<T>): Observable<T> {
    return combineLatest([
      obs$,
      this._algoLabelsList.getStream(),
      this._exchangeInfo.getStream(),
      this._providerInfo.getStream().pipe(catchError(() => of({}))),
    ]).pipe(
      tap(([, algoLabels, providerInfo, exchangeInfo]) => {
        if (
          Object.keys(algoLabels).length === 0 ||
          Object.keys(providerInfo).length === 0 ||
          Object.keys(exchangeInfo).length === 0
        ) {
          this._router.navigate(['/error']);
        }
      }),
      map(([data]) => data),
      catchError((err) => {
        console.error('[Meta]: Error loading crucial data', err);
        // this._router.navigate(['/error']);
        throw err;
      })
    );
  }
}
