import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { parseCookie } from '@core/utils';
import { MetaService } from '@core/services/api/meta.service';

/**
 * Injects token into a header.
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private _meta: MetaService) {}
  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this._getToken();
    const extRef = this._meta.outerReferrer;
    const isEmbedding = this._meta.isEmbedding;

    const newRequest = request.clone({
      withCredentials: true,
      setHeaders: {
        ...(token && { 'x-bm-auth-token-mp': token }),
        ...(extRef && { 'external-referrer': extRef }),
        ...(isEmbedding && { 'mp-embedded': 'true' }),
      },
    });
    return next.handle(newRequest);
  }

  private _getToken(): string {
    return parseCookie(document.cookie)['x-bm-auth-token'];
  }
}
