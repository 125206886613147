import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkDialogContainer, DialogModule } from '@angular/cdk/dialog';
import { A11yModule } from '@angular/cdk/a11y';

@Component({
  selector: 'app-modal-frame',
  standalone: true,
  imports: [CommonModule, DialogModule, A11yModule],
  templateUrl: './modal-frame.component.html',
  styleUrls: ['./modal-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalFrameComponent extends CdkDialogContainer {}
